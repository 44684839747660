
.design-examples-title {
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0 0 30px 5%;
}
.design-examples-title h2 {
    width: 25vw;
    font-family: var(--serif);  
    font-size: 1.5em;
    margin-right: 16px;
}
.examples-nav-list {
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
}
.examples-nav-item {
    font-size: 1.2em;
    padding: 0 12px;
    color: var(--pink-color);
    border-right: 1px solid rgba(255, 255, 255, 0.8);
}
.design-examples-title div div:last-child .examples-nav-item {
    border: none;
}
.examples-nav-item:hover {
    color: #fff;
    cursor: pointer;
} 
.selected-nav .examples-nav-item {
    color: rgba(255, 255, 255, 0.8);
    cursor:default;
}
.design-examples {
    display: flex;
    height: 90vh;
    width: 100%;
    flex-direction: column-reverse;
    justify-content: center;
}
.design-example-item {
    width: 100vw;
    display: flex;
    flex-direction: row;
}
.design-example-text {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8vw;
}
.design-example-text h2 {
    font-size: 1.1em;
    margin-bottom: 5px;
}
.design-example-text p {
    font-size: 1em;
    font-weight: 300;
    color: #cccccc  
}
.design-example-text .role-info {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: .9em;
}
.image-carousel {
    width: 100vw;
    height:50vh;
    display: flex;
    align-items:center;
}
.carousel-container {
    width: 84vw;
    margin: 0 8vw;
    overflow: hidden;
}
.carousel-inner {
    white-space: nowrap;
    transition: transform 0.3s;
    display: flex;
    align-items: center;
}
.slide {
    min-width: 84vw;
    transition: margin 0.5s;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.slide img {
    max-width:100%;
    max-height: 50vh;
}
.arrow {
    display: none;
}

/* ----- Animations? ----- */
.example-enter {
    opacity: 0;
}
.example-enter-active {
    opacity: 1;
    transition: opacity 100ms;
}
.example-exit {
    opacity: 1;
}
.example-exit-active {
    opacity: 0;
    transition: opacity 100ms;
}
.example-exit-done {
    opacity: 0;
}

@media (min-height: 800px) {
    .image-carousel {
        height: 56vh;
    }
    .slide {
        padding: 0 2px;
    }
    .slide img {
        max-height: 56vh;
    }
}

@media (min-width: 700px) {
    .design-examples-title {
        padding: 0 8vw 4vh 8vw;
    }
    .design-examples-title h2 {
        font-size: 1.8em;
        width: auto
    }
    .examples-nav-item {
        font-size: 1.5em;
        padding: 0 20px;
    }
}

@media (min-width:1024px) {
    .design-examples {
        flex-direction: row;
    }
    .design-example-text {
        width: 40vw;
    }
    .image-carousel {
        width: 60vw;
        height: 80vh;
        margin-right: 3vw;
    }
    .carousel-container {
        width: calc(60vw - 80px);
        margin:0;
    }
    .slide {
        min-width: calc(60vw - 80px);
        padding: 1vw;
    }
    .slide img {
        max-height: 70vh;
    }
    .arrow {
        display: block;
        background: rgb(255,255,255);
        background: radial-gradient(circle at bottom left, rgba(255,255,255,1) 20%, rgba(255,255,255,0.0) 75%);
        height: 30px;
        width:30px;
        opacity: 0.0;
        transition: opacity 0.3s;
    }
    .arrow-inside {
        width: 29px;
        height: 29px;
        float:right;
        background: black;
    }
    .left-arrow {
        transform: rotate(45deg);
    }
    .right-arrow {
        transform: rotate(225deg)
    }
    #active-arrow {
        opacity: 0.7;
        cursor: pointer;
    }
    #active-arrow:hover {
        opacity: 1;
    }
    .design-examples-title {
        padding: 0 4vw 2vh 4vw;
        align-items: baseline;
    }
    .design-examples-title h2 {
        display: inline-block;
        width: 23%;
        margin: 0;
    }
    .examples-nav-item {
        font-size: 1.4vw;
        padding: 0 10px;
    }
    .unselected-nav::after {
        transition: width 0.3s, margin 0.3s;
        width: 0;
        margin: 0 50%;
        display: block;
        content: " ";
        height: 1px;
        min-height:1px;
        background: var(--trans-gradient);
        margin-top: 20px;
    }
    .unselected-nav:hover::after {
        width:calc(100% - 40px);
        margin: 20px 20px;
        
    }
    /* .example-enter {
        transform:scale(.9);
    }
    .example-enter-active {
        transform: scale(1);
        transition: transform 300ms;
    } */
}
@media (min-width:1180px) {
    .design-examples-title h2 {
        width: auto;
        margin-right: 20px;
    }
}
@media (min-width: 1366px) {
    .design-examples-title h2{
        font-size: 2em;
    }
    .examples-nav-item {
        font-size: 1em;
        padding: 0 20px;
    }
}

